import * as React from "react"
import { Link, graphql } from "gatsby"
import lockscreen from '../images/lock-screen.jpg'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '../../node_modules/@splidejs/react-splide/dist/css/splide.min.css'

import Layout from "../components/layout"
import Seo from "../components/seo"

import { withAuthenticator, Authenticator, AmplifySignIn, AmplifySignUp } from "aws-amplify-react"

const VideosPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    
    const posts = data.webiny.listAlphaBetasPosts.data
  
    if (posts.length === 0) {
      return (
        <Layout location={location} title={siteTitle}>
          <Seo title="All Deleted Scenes" />
          <p>
            No Video posts found.
          </p>
        </Layout>
      )
    }


    const youTubeVideos = [
      {
        id: 1,
        url: 'https://www.youtube.com/embed/_PUt6q8KRsE',
        title: 'Alpha Betas - This is Alpha Team (Pilot Episode)',
      },
      {
        id: 2,
        url: 'https://www.youtube.com/embed/LaukYn0FC2k',
        title: 'Alpha Betas - One of Us is Getting Canceled! (Episode 2)',
      },
      {
        id: 3,
        url: 'https://www.youtube.com/embed/DGClFUvbnOg',
        title: 'Alpha Betas - The Zombie Deathrun (Episode 3)',
      },
      {
        id: 4,
        url: 'https://www.youtube.com/embed/MMfsYcmbrjc',
        title: 'Alpha Betas - The Filthy Password (Episode 4)',
      },
      {
        id: 5,
        url: 'https://www.youtube.com/embed/zxH6h5YRSKo',
        title: 'Alpha Betas - One Last Mission (Episode 5)',
      },
    ];

  
    return (
      <Layout location={location} title={siteTitle} className="bg-black">
        <Seo title="All Deleted Scenes" />
        <div className="pt-20 videosPage">

          <div className="inline-block w-full">
              <div className="mx-auto py-10 md:py-20 px-5 m-0 w-full md:w-1/2">
                  <div className="text-center">
                    <h1 className="text-4xl md:text-5xl shadow-2xl font-GodaBold uppercase mb-5 text-ab-yellow-600">Videos</h1>
                  </div>
              </div>
          </div>




          <div className="VideoCarouselSlider bg-ab-blue-200 w-full px-10 pb-10">

            <div className="inline-block w-full">
                <div className="mx-auto py-10 md:py-20 px-5 m-0 w-full md:w-1/2">
                    <div className="text-center">
                      <h2 className="text-4xl md:text-5xl shadow-2xl font-GodaBold uppercase mb-5 text-ab-yellow-600">Episode Gallery</h2>
                      <div className="">
                        <p className="font-CobeSemiBold leading-6 text-lg">Watch all episodes and bonus content here</p>
                      </div>
                    </div>
                </div>
            </div>

            <div className="block max-w-7xl mx-auto">
              <Splide 
                  options={ {
                    rewind: true,
                    pagination: false,
                    width : '100%',
                    perPage: 2,
                    gap   : '3rem',
                    drag   : 'free',
                    breakpoints: {
                      1024: {
                        perPage: 2,
                      },
                      768: {
                        perPage: 1,
                        snap: false,
                      },
                    },
                  } }
                  className="CarouselSlider">
                    {youTubeVideos.map((video) => (
                      <SplideSlide key={video.id} className="py-2">
                        <div className="mb-5 lg:mb-0">
                            <div className="rounded-lg bg-cover">
                            <iframe
                              src={video.url}
                              width="100%" 
                              height="315"
                              style={{ maxWidth: '100%', maxHeight: '50vh' }}
                              frameborder="0" 
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="YouTube video player"
                              className="rounded-3xl"
                            />
                            </div>
                            <h2 className="my-3 leading-6 text-lg font-CobeSemiBold">
                            <div className="w-full">{video.title}</div>
                            </h2>
                        </div>
                      </SplideSlide>
                    ))}
                </Splide>
            </div>

          </div>

       

          <div className="inline-block bg-ab-blue-300 w-full p-5 md:py-10 md:px-10">

              <div className="inline-block w-full">
                  <div className="mx-auto py-10 px-5 m-0 w-full md:w-1/2">
                      <div className="text-center">
                        <h2 className="text-4xl md:text-5xl shadow-2xl font-GodaBold uppercase mb-5 text-ab-yellow-600">Deleted Scenes</h2>
                        <div className="">
                          <p className="font-CobeSemiBold leading-6 text-lg">NSFYT (Not Safe For YouTube) scenes that were removed from the main episodes but you have exclusive access to see them here!</p>
                        </div>
                      </div>
                  </div>
              </div>

              <div className="mx-auto max-w-4xl py-5 px-0 sm:px-6 lg:py-5 lg:px-8">
                <div className="text-white text-sm grid grid-cols-auto md:grid-cols-4 gap-6 space-y-0 lg:gap-x-8">
                      {posts.map(post => {
                      return (
                          <div key={post.slug} className="bg-ab-blue-200 p-5 rounded-lg mb-0 col-span-0 md:col-span-2">
                              <article
                                  className="post-list-item space-y-4"
                                  itemScope
                                  itemType="http://schema.org/Article"
                              >
                              {/* <Authenticator hideDefault={true}>
                                  <span itemProp="headline">Welcome</span>
                              </Authenticator> */}
                              <Link to={post.slug} itemProp="url">
                                  <div className="aspect-w-3 aspect-h-2">
                                  <img src={post.featuredImage} width={1920} height={1080} className="featureImage rounded-lg object-cover" />
                                  </div>
                                  <h2 className="my-3 font-normal">
                                      <span itemProp="headline ">{post.title}</span>
                                  </h2>
                                  </Link>
                              </article>
                          </div>
                      )
                      })}
                      {/* <div className="bg-ab-blue-200 rounded p-5 rounded-lg shadow-lg mb-5 lg:mb-0">
                          <img src={lockscreen} className="featureImage rounded-lg object-cover shadow-lg h-[230px]" />
                          <h2 className="my-3 font-normal">
                              <span itemProp="headline">EPISODE 5 - DELETED SCENE</span>
                              <br />
                              <span className="text-xs">(Locked Until 11/14 12PM PT)</span>
                          </h2>
                      </div> */}
                  </div>
              </div>
          </div>

        </div>
      </Layout>
    )
  }
  
  export default VideosPage 

  // export default withAuthenticator(VideosPage, {
  //   signUpConfig: {
  //     signUpFields: [
  //         {
  //             label: 'Email',
  //             key: 'username',
  //             required: true,
  //             type: 'email',
  //             placeholder: 'Email',
  //             displayOrder: 1,
  //         },
  //         {
  //             label: 'Password',
  //             key: 'password',
  //             required: true,
  //             type: 'password',
  //             placeholder: 'Password',
  //             displayOrder: 2,
  //         },
  //         {
  //             label: 'Phone Number (optional)',
  //             key: 'phone_number',
  //             required: false,
  //             type: 'phone_number',
  //             displayOrder: 3,
  //         }
  //     ],
  //     signIn: [
  //       {
  //           label: 'Email',
  //           key: 'username',
  //           required: true,
  //           type: 'email',
  //           placeholder: 'Email',
  //           displayOrder: 1,
  //       },
  //       {
  //           label: 'Password',
  //           key: 'password',
  //           required: true,
  //           type: 'password',
  //           placeholder: 'Password',
  //           displayOrder: 2,
  //       }
  //   ],
  //     hiddenDefaults: ['username', 'email'],
  //   }
  // })
  
  export const pageQuery = graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      webiny {
        listAlphaBetasPosts(sort: createdOn_DESC) {
          data {
            id
            title
            slug
            description
            createdOn
            featuredImage
            textTracks
            visibility
            video
          }
        }
      }
    }
  `
  